import Vue from 'vue'
import vueCustomElement from 'vue-custom-element';

import App from './App.vue'
import store from './store';

Vue.config.ignoredElements = [
  'community-searchbar'
];

Vue.config.productionTip = false

// new Vue({
//   store,
//   render: h => h(App),
// }).$mount('#app')

Vue.use(vueCustomElement);

App.store = store;

Vue.customElement('community-searchbar', App);
