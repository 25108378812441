<template>
    <div>
        <div class='rp-corp-date' v-if="visible">
            <date-picker  
                v-model="selectedDate"
                type="date"
                format="MMM DD, YYYY"
                placeholder="Move in Date" 
                :disabled-date="disabledDays"   
                @change="updateSelectedFields(selectedDate)"          
                >
            </date-picker>
        </div>
    </div>
</template>

<script>
import store from '../store';
import DatePicker from 'vue2-datepicker'

export default {
    components: { DatePicker },
    data: function() { 
        return {
            selectedDate: '',
            displayMoveDate: true
        }
    },
    props: {
        displaymoveindate: {
            type: String,
            default: ''
        },
        futureavailability: {
            type: String,
            default: 'none'
        }
    },
    computed: {
        daysLimit() {
            if(this.futureavailability === 'none')
                return 99999
            else
                return this.futureavailability
        },
        visible: function () {
            try{
                return this.displaymoveindate.toLowerCase() === 'true';
            }catch(err){
                //ignore
                return false;
            }
        }
    },
    methods: {
        disabledDays(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            
            return date < today || date > new Date(today.getTime() + this.daysLimit * 24 * 3600 * 1000);
        },
        updateSelectedFields(value) {
            store.dispatch('setSelectedMoveDate', value);
        }
    }
}
</script>

<style>
</style>