<template>
    <div class="corporate-text-input">
        <input 
        type='text' 
        :placeholder="errorMsg"
        v-model="searchText"
        :class="{required: preventemptysearch.toLowerCase() === 'true' && errorMessage == true}"
        @click.stop="setLocationOpen(true)"
        @keydown.enter.prevent="submitForm()">
        <div
            id="locationFilterModal"
            class="single-filter-modal"
            v-if="locationOpen"
            v-click-outside="hideModal" 
        > 
            <ul
            v-if="filteredCities.length > 0" 
            class="location-group">
                <div class="group-label">
                    Cities
                </div>
                <li
                    v-for="(city, index) in filteredCities"
                    :key="'city' + index"
                    @click="filterByCity(city.name, city.state)"
                >
                    {{ city.name }}, {{ city.state}}
                </li>
            </ul>
            <ul
            v-if="filteredStates.length > 0" 
            class="location-group">
                <div class="group-label">
                    States
                </div>
                <li
                    v-for="(state, index) in filteredStates"
                    :key="index + 'code' + state.code"
                    @click="filterByState(state.name)"
                >
                    {{ state.name }}
                </li>
            </ul>         
            <ul
            v-if="filteredCommunities.length > 0" 
            class="location-group">
                <div class="group-label">
                    Communities
                </div>
                <li
                    
                    v-for="(community, index) in filteredCommunities"
                    :key="'city' + index"
                    @click="filterByCommunity(community.name)"
                >
                    {{ community.name }} - {{ community.stateName }}
                </li>   
            </ul>
            <ul
            v-if="filteredCommunities.length == 0 && filteredCities.length == 0 && filteredStates.length == 0"
            class="location-group">
                <li>
                    No matching results
                </li>
            </ul>   
        </div>
    </div>
    
</template>

<script>
import '@/directives.js';
import { mapState } from 'vuex';
import store from '@/store';

export default {
    data: function() {
        return {
            searchText: '',
        }
    },
    props: {
        preventemptysearch: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState(['locationOpen', 'searchLocations', 'errorMessage']),
      communitiesByName() {
        var names = this.searchLocations
        return names
      },
      cityList() {
        var properties = this.searchLocations
        var cityList = []
        properties.forEach(function(property) {
          const cityFound = cityList.find(a => a.name === property.city)
          if (!cityFound) {
            var item = {
              'name': property.city,
              'state': property.stateName
            }
            cityList.push(item)
          }
          if (cityFound) {
            const matchingCities = cityList.filter(a => a.name === property.city)
            const matchingStates = matchingCities.find(a => a.state === property.stateName)            
            if (!matchingStates) {
              var item2 = {
                'name': property.city,
                'state': property.stateName
              }
            cityList.push(item2)
            }
          }
        })

        return cityList;
      },
      stateList() {
        var properties = this.searchLocations
        var stateList = []
        properties.forEach(function(property) {
          const found = stateList.find(a => a.name === property.stateName)
          if (!found) {
            var item = {
              'name': property.stateName,
              'code': property.stateCode,
            }
            stateList.push(item)
          }
        })
        
        return stateList
      },
      filteredCommunities() {
          return this.communitiesByName.filter((c) => {
              return c.name.toLowerCase().match(this.searchText.toLowerCase())
          })
      },
      filteredCities() {
          return this.cityList.filter((city) => {
              return city.name.toLowerCase().match(this.searchText.toLowerCase());
          })
      },
      filteredStates() {
          return this.stateList.filter((state) => {
              return state.name.toLowerCase().match(this.searchText.toLowerCase());
          })
      },
      requiredFields() {
          if(store.state.selectedFields.city == null &&
          store.state.selectedFields.state == null &&
          store.state.selectedFields.community == null && 
          this.preventemptysearch.toLowerCase() === 'true' &&
          this.errorMessage === true)
            return true
        else
            return false
      },
      errorMsg() {
          if(this.preventemptysearch.toLowerCase() === 'true' && this.requiredFields == true )
            return "Please enter a city, state, or community"
        else
            return "City, State, Community"
      }
    },
    methods: {
        setLocationOpen(status) {
            store.dispatch('getLocationOpen', status)
            store.dispatch('setErrorMessage', false);
        },
        filterByCity(city, state) {
            this.searchText = city;
            store.dispatch('setSelectedCity', city)
            store.dispatch('setSelectedState', state)
            store.dispatch('setErrorMessage', false);
            this.setLocationOpen(false)
        },
        filterByCommunity(name) {
            this.searchText = name;
            store.dispatch('setSelectedCommunity', name)
            store.dispatch('setErrorMessage', false);
            this.setLocationOpen(false)
        },
        filterByState(state) {
            this.searchText = state.trim();
            store.dispatch('setSelectedState', state)
            store.dispatch('setErrorMessage', false);
            this.setLocationOpen(false)
        },
        hideModal() {
            if(store.state.selectedFields.city == null &&
                store.state.selectedFields.state == null &&
                store.state.selectedFields.community == null) {
                    store.dispatch('setErrorMessage', true)
                }
            store.state.locationOpen = false;
        },
        submitForm() {
            this.setLocationOpen(false);
            this.$root.$refs.SearchBarSubmit.submitInfo();
        }
    },
}
</script>

<style lang="scss">
</style>