<template>
    <div class='rp-bed-bath' v-if="displaybedbath.toLowerCase() === 'true'" >
        <button 
            type="button"
            role="button"
            class="rp-button"
            :class="{'selection': bedsBathsActive}"
            @click.stop="openModal()"          
        >
        {{ buttonText }}
        <SvgChevronDown v-if="isModalOpen" />
        <SvgChevronUp v-if="!isModalOpen"/>
        </button>        
        <SearchBarBedBathModal v-if="isModalOpen" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import SvgChevronDown from '@/components/SvgChevronDown.vue';
import SvgChevronUp from '@/components/SvgChevronUp.vue';
import SearchBarBedBathModal from '@/components/SearchBarBedBathModal.vue';


export default {
    components: {
        SvgChevronDown,
        SvgChevronUp,
        SearchBarBedBathModal       
    },
    data: function() {
        return {
            selectedBed: null,
            selectedBath: null,
        }
    },
    props: {
        displaybedbath: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState([ 'selectedFields', 'isModalOpen']),
        buttonText() {
            if(this.selectedFields.beds == null && this.selectedFields.baths == null)
                return "All Beds & Baths"
            else if(this.selectedFields.beds == null && this.selectedFields.baths  > 0 )
                return "All Beds & " + this.selectedFields.baths  + "+ Baths"
            else if(this.selectedFields.beds > 0 && this.selectedFields.baths  == null)
                return this.selectedFields.beds + "+ Beds & All Baths"
            else if(this.selectedFields.beds == 0 && this.selectedFields.baths  == null)
                return "Studio & All Baths"
            else if(this.selectedFields.beds == 0 && this.selectedFields.baths  > 0)
                return "Studio & " + this.selectedFields.baths  + "+ Baths"
            else if(this.selectedFields.beds > 0 && this.selectedFields.baths  > 0)
                return this.selectedFields.beds + "+ Beds & " + this.selectedFields.baths  + "+ Baths"
            
            return "All Beds & Baths"
        },
        bedsBathsActive() { 
            if (this.isModalOpen) {
                return true
            }
            if (this.selectedFields.beds != null || this.selectedFields.baths != null) {
                return true
            }
            return false
        },
    },
    methods: {      
        openModal() {
            store.dispatch('setIsModalOpen', true);
        },
    }
    
}
</script>

<style lang="scss">

</style>