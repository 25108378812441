<template>
    <div class='rp-corp-searchbar-submit'>
        <button 
        class="corp-submit"
        ref='searchsubmit'
        type="button"
        @click="submitInfo()" 
        @keyup.enter="submitInfo()"
        >          
            <svg 
                id="searchArea" 
                width="20px"
                height="21px" 
                viewBox="0 0 20 21" 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <title>Search</title>
                <g 
                stroke="none" 
                stroke-width="1" 
                fill="none" 
                fill-rule="evenodd"
                >
                <g 
                    transform="translate(-530.000000, -52.000000)" 
                    fill="#fff" 
                    fill-rule="nonzero"
                >
                    <g 
                    transform="translate(252.000000, 40.000000)"
                    >
                    <path 
                        id="s"
                        d="M296.980439,32.3632756 L297.863234,31.4804805 C298.046835,31.2968795 298.046835,30.9999824 297.863234,30.8164196 L293.121042,26.0742281 C293.031207,25.9843922 292.914019,25.9375097 292.789012,25.9375097 L292.273419,25.9375097 C293.503885,24.5117318 294.249961,22.6562675 294.249961,20.6250224 C294.249961,16.1367365 290.613266,12.5000417 286.124981,12.5000417 C281.636695,12.5000417 278,16.1367365 278,20.6250224 C278,25.1133082 281.636695,28.750003 286.124981,28.750003 C288.156226,28.750003 290.01169,28.0039263 291.437468,26.7734605 L291.437468,27.2890537 C291.437468,27.414061 291.488241,27.5312483 291.574186,27.6210842 L296.316378,32.3632756 C296.499941,32.5468766 296.796838,32.5468766 296.980439,32.3632756 Z M286.124981,26.8750075 C282.671849,26.8750075 279.874996,24.0781544 279.874996,20.6250224 C279.874996,17.1718903 282.671849,14.3750373 286.124981,14.3750373 C289.578113,14.3750373 292.374966,17.1718903 292.374966,20.6250224 C292.374966,24.0781544 289.578113,26.8750075 286.124981,26.8750075 Z" 
                    />
                    </g>
                </g>
                </g>
            </svg>
        </button>
    </div>
</template>

<script>
import store from '@/store';
import EventBus from '../shared/event-bus.js';
import mapState from 'vuex'

export default {
    data: function() {
        return {
            
        }
    },
    props: {
        pmcid: {
            type: String,
            default: '32706'
        },
        communitysearchpage: {
            type: String,
            default: ''
        },
        preventemptysearch: {
            type: String,
            default: 'true'
        },
        folder: {
            type: String,
            default: ''
        }
    },
    created() {
        this.$root.$refs.SearchBarSubmit = this;
    },  
    methods: {
        submitInfo() {         
            var preventEmptySearch = this.preventemptysearch.toLowerCase();

            if ((preventEmptySearch === 'true'
            && ((this.$store.state.selectedFields.name !== null) || this.$store.state.selectedFields.city !== null || this.$store.state.selectedFields.state !== null)) 
            || (preventEmptySearch === 'false')) { 
                store.dispatch('setErrorMessage', false);
                var CSWidget = store.state.selectedFields;
                this.$cookies.set('CSWidget_' + this.pmcid, JSON.stringify(CSWidget));
                window.EventBus.$emit('set-cs-filter-cookie', this.pmcid);
                var isCommunitySearchOnPage = document.getElementById('community-search-scroll-wrapper');
                var url = '';

                if(this.folder !== '') {
                    url = window.location.origin + '/' + this.folder + this.communitysearchpage; 
                    window.open(url, '_self');
                }
                else if(isCommunitySearchOnPage === null){
                    url = window.location.origin + '/' + this.communitysearchpage; 
                    window.open(url, '_parent');
                }
                else {
                    document.getElementById('community-search-scroll-wrapper').scrollIntoView({
                        behavior: 'smooth'
                    })
                }
            }
            else {
                store.dispatch('setErrorMessage', true);
            }
        },
    },
}
</script>

<style lang="scss">
</style>