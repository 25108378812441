<template>
  <div id="community-searchbar" :style="colors">
    <RPHome 
    :pmcid="pmcid"
    :apikey="apikey"
    :preventsearch="preventsearch"
    :folder="folder"
    :displaybedbath="displaybedbath"
    :displaymoveindate="displaymoveindate"
    :futureavailability="futureavailability"
    :communitysearchpage="communitysearchpage"/>
  </div>
</template>

<script>
import store from './store';
import RPHome from './views/rpHome.vue'

export default {
  store,
  name: 'App',
  components: {
    RPHome,
  },
  props: {
        pmcid: {
            type: String,
            default: '822'
        },
        apikey: {
            type: String,
            default: 'key123'
        },
        folder: {
          type: String,
          default: ''
        },
        preventsearch: {
            type: String,
            default: 'false'
        },
        displaybedbath: {
            type: String,
            default: 'true'
        },
        displaymoveindate: {
            type: String,
            default: 'true'
        },
        futureavailability: {
            type: String,
            default: 'none'
        },
        communitysearchpage: {
            type: String,
            default: 'Community-v2.aspx'
        },
        rpcolors: {
            type: String,
            default: '["#d8d8d8", "#000378","#355e3b", "#610505","#669335", "#c48282","#c00a29", "#efe860","#078d58"]'
        },
        
    },
    async created() {
        store.dispatch('setRPColors', this.rpcolors);
    },
    computed: {
      colors() {
        return this.$store.state.colors;
      }
    },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#community-searchbar {
    font-size: 62.5%; 
    letter-spacing: .5px;
    font-family: 'Poppins', sans-serif;
}

html {
   font-size: 62.5%; 
    letter-spacing: .5px;
    font-family: 'Poppins', sans-serif;
}
</style>
