import * as axios from 'axios';
import "regenerator-runtime/runtime.js";


const parseList = (response) => {
    if(response.status !== 200) throw Error(response.message);
    if(!response.data) return [];
    let list = response.data;
    if (typeof list!== 'object') {
        list = []
    }
    return list;
};

const getCommunitySearchInternal = async function (id, key) {
    try {
      //const url = `https://api.dev-ws.realpage.com/v2/communitysearch/${id}`;
      //const response = await axios.get(url, { headers: {'x-ws-authkey': '35eb5e47-6484-42df-8e3e-309e297349fd' }}, {timeout: 6000}); 
      const url = `https://#{APIHost}#/v2/communitysearch/${id}`;
      const response = await axios.get(url, { headers: {'x-ws-authkey': '#{ApiKey}#'}}, {timeout: 6000 });     
      const data = parseList(response);
      return data.response;
    }
    catch (error){
        return '';
    }
};

export const dataService = {
    getCommunitySearchInternal,
}