const helpers = {
    transformConfig: function (config) {
        try {
            let colors = config;

            if (colors) {
                colors = colors.substring(1, colors.length - 1)
                    .split(',')
                    .map(function (b) {
                        b = b.trim();
                        return b.substring(1, b.length - 1);
                    });
                
                config = `
                --RPcolor1: ${colors[0]};
                --RPcolor2: ${colors[1]};
                --RPcolor3: ${colors[2]};
                --RPcolor4: ${colors[3]};
                --RPcolor5: ${colors[4]};
                --RPcolor6: ${colors[5]};
                --RPcolor7: ${colors[6]};
                --RPcolor8: ${colors[7]};
                --RPcolor9: ${colors[8]};
                `;             
            }
            config = config.replace('\n', '').trim();
            return config;
        }
        catch (error) {
            console.log('Erroring parsing colors\n' + error);
            return config;
        }
    }
}

export default helpers;