<template>
    <div class='rp-corp-searchbar'>
        <searchBarByArea :preventemptysearch="preventsearch"/>
        <searchBarBedBath :displaybedbath="displaybedbath"/>
        <searchBarDate :displaymoveindate="displaymoveindate" :futureavailability="futureavailability"/>
        <searchBarSubmit :pmcid="pmcid" :communitysearchpage="communitysearchpage" :preventemptysearch="preventsearch" :folder="folder" />
    </div>
</template>

<script>
import Vue from 'vue';
import store from '@/store';
import vueCookies from 'vue-cookies'
import searchBarByArea from '../components/SearchBarByArea';
import searchBarBedBath from '../components/SearchBarBedBath';
import searchBarDate from '../components/SearchBarDate';
import searchBarSubmit from '../components/SearchBarSubmit';
import { mapActions } from 'vuex';

Vue.use(vueCookies);

export default {
    name: 'home',
    store,
    components: {
        searchBarByArea,
        searchBarBedBath,
        searchBarDate,
        searchBarSubmit,
    },
    props: {
        pmcid: {
            type: String,
            default: '822'
        },
        apikey: {
            type: String,
            default: 'key123'
        },
        folder: {
            type: String,
            default: ''
        },
        preventsearch: {
            type: String,
            default: ''
        },
        displaybedbath: {
            type: String,
            default: ''
        },
        displaymoveindate: {
            type: String,
            default: ''
        },
        futureavailability: {
            type: String,
            default: 'none'
        },
        communitysearchpage: {
            type: String,
            default: 'Community-v2.aspx'
        },
        rpcolors: {
            type: String,
            default: ''
        },
        
    },
    async created() {
        await this.getCommunitySearch(this.pmcid, this.apikey);
        store.dispatch('setRPColors', this.rpcolors);
    },
    computed: {
        colors() {
            return this.$store.state.colors;
        }
    },
    methods: {
        ...mapActions(['getCommunitySearchFromAPI']),
        async getCommunitySearch(id, key) {
            var payload = {id, key};
            await this.getCommunitySearchFromAPI(payload);
        }
    }
}
</script>

<style lang="scss">
@import 'src/design/variables.scss';
@import 'src/design/datepicker-override.scss';
@import 'src/design/community-searchbar.scss';
</style>