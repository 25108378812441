<template>
    <div 
            id="bedsBathsFilterModal" 
            class="single-filter-modal-1"   
            v-click-outside="hideBedBathModal"              
        >
            <div class="beds-bath-modal"  >
                <div class="beds-label">
                <label>Beds</label>
                </div>
            <div 
            id="bedsModalFilter" 
            class="filter-button-row"
            >
            <button 
                type="button" 
                role="button" 
                class="row-button beds-button"
                :class="{ active: selectedFields.beds === null }"
                @click="applyBeds(null)"
            >
                All
            </button>
            <button 
                type="button" 
                role="button" 
                class="row-button beds-button"
                :class="{ active: selectedFields.beds === 0 }"
                @click="applyBeds(0)"
            >
                Studio
            </button>
            <button 
                type="button" 
                role="button" 
                class="row-button beds-button"
                :class="{ active: selectedFields.beds === 1 }"
                @click="applyBeds(1)"
            >
                1+
            </button>
            <button 
                type="button" 
                role="button" 
                class="row-button beds-button"
                :class="{ active: selectedFields.beds === 2 }"
                @click="applyBeds(2)"
            >
                2+
            </button>
            <button 
                type="button" 
                role="button" 
                class="row-button beds-button"
                :class="{ active: selectedFields.beds === 3 }"
                @click="applyBeds(3)"
            >
                3+
            </button>
            </div>
            <div class="baths-label">
            <label>Baths</label>
            </div>
            <div 
            id="bathsModalFilter" 
            class="filter-button-row"
            >
            <button 
                type="button" 
                role="button" 
                class="row-button baths-button"
                :class="{ active: selectedFields.baths === null }"
                @click="applyBaths(null)"
            >
                All
            </button>
            <button 
                type="button" 
                role="button" 
                class="row-button baths-button"
                :class="{ active: selectedFields.baths === 1 }"
                @click="applyBaths(1)"
            >
                1+
            </button>
            <button 
                type="button" 
                role="button" 
                class="row-button baths-button"
                :class="{ active: selectedFields.baths === 2 }"
                @click="applyBaths(2)"
            >
                2+
            </button>
            <button 
                type="button" 
                role="button" 
                class="row-button baths-button"
                :class="{ active: selectedFields.baths === 3 }"
                @click="applyBaths(3)"
            >
                3+
            </button>
            </div>
            </div>
        </div>
</template>

<script>
import store from '@/store';
import {mapState} from 'vuex';

export default {
    data: function() {
        return {
            isopen: true,
        }
    },
    computed: {
        ...mapState([ 'selectedFields', 'isModalOpen']),
    },
    methods: {
        applyBeds(value) {
            this.selectedBed = value;
            store.dispatch('setSelectedBeds', value)
        },
        applyBaths(value) {
            this.selectedBath = value;
            store.dispatch('setSelectedBaths', value)
        },
        hideBedBathModal() {
            store.dispatch('setIsModalOpen', false);
        }
    },
}
</script>

<style lang="scss">

</style>