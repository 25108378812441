import Vue from 'vue';
import Vuex from 'vuex';
import { dataService } from '../shared';
import helpers from '../utils/helpers.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
      communitySearch: '',
      filteredIn: '',
      locationOpen: false,
      searchLocations: '',
      isModalOpen: false,
      selectedFields: {
        city: null,
        state: null,
        name: null,
        beds: null,
        baths: null,
        moveDate: null
      },
      colors: [],
      errorMessage: false,
      
    },
    mutations: {
      GET_COMMUNITYSEARCH(state, communitySearch) {
        state.communitySearch = communitySearch;
      },
      GET_FILTEREDIN(state, filteredIn) {
        state.filteredIn = filteredIn;
      },
      GET_LOCATIONOPEN(state, locationOpen) {
        state.locationOpen = locationOpen
      }, 
      SET_SEARCHLOCATIONS(state, searchLocations) {
        state.searchLocations = searchLocations
      },
      SET_SELECTEDCITY(state, city){
        state.selectedFields.city = city
      },
      SET_SELECTEDSTATE(state, s) {
        state.selectedFields.state = s
      },
      SET_SELECTEDCOMMUNITY(state, name) {
        state.selectedFields.name = name
      },
      SET_SELECTEDBEDS(state, beds) {
        state.selectedFields.beds = beds
      },
      SET_SELECTEDBATHS(state, baths) {
        state.selectedFields.baths = baths
      },
      SET_SELECTEDMOVEDATE(state, moveDate) {
        state.selectedFields.moveDate = moveDate
      },
      IS_MODAL_OPEN(state, isModalOpen){
        state.isModalOpen = isModalOpen
      },
      SET_COLORS(state, colors) {
        state.colors = colors
      },
      SET_ERRORMESSAGE(state, value) {
        state.errorMessage = value;
      }
    },
    actions: {
      async getCommunitySearchFromAPI({commit}, payload) {
        const communitySearch = await dataService.getCommunitySearchInternal(payload.id, payload.key);
        commit('GET_COMMUNITYSEARCH', communitySearch)
        const properties = communitySearch.properties
        commit('GET_FILTEREDIN', properties)
        var searchList = []
        properties.forEach(function(prop) {
          var address = prop.address
          var searchItem = {
            'name': prop.name,
            'city': address.cityName,
            'stateName': address.stateName,
            'stateCode': address.stateCode,
            'postalCode': address.postalCode,
            'propertyId': prop.id
          }
          searchList.push(searchItem)
        })
        commit('SET_SEARCHLOCATIONS', searchList)
      },
      getLocationOpen({commit}, locationOpen) {
        commit('GET_LOCATIONOPEN', locationOpen)
      },
      setSelectedCity({commit}, city) {
        commit('SET_SELECTEDCITY', city)
      },
      setSelectedState({commit}, s) {
        commit('SET_SELECTEDSTATE', s)
      },
      setSelectedCommunity({commit}, name) {
        commit('SET_SELECTEDCOMMUNITY', name)
      },
      setSelectedBeds({commit}, beds) {
        commit('SET_SELECTEDBEDS', beds)
      },
      setSelectedBaths({commit}, baths) {
        commit('SET_SELECTEDBATHS', baths)
      },
      setSelectedMoveDate({commit}, moveDate) {
        commit('SET_SELECTEDMOVEDATE', moveDate)
      },
      setIsModalOpen({commit}, isModalOpen) {
        commit('IS_MODAL_OPEN', isModalOpen);
      },
      setRPColors({commit}, colors) {
        let localColors = {};
        localColors = helpers.transformConfig(colors);
        commit('SET_COLORS', localColors);
      },
      setErrorMessage({commit}, value) {
        commit('SET_ERRORMESSAGE', value);
      }
    },
    modules: {
    },
  });